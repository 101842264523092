body {
  background: #5c82c2;
}

.container {
  padding-bottom: 4px 4px 160px 4px;
  padding: 4px !important;
  width: 80%;
  margin: auto;
  margin-top: 160px !important;
  margin-bottom: 40px !important;
}

.contentForm {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #43bedd;
  border-radius: 35px;
  margin-bottom: 20px;
}

.contentIcon {
  width: 100%;
  max-width: 60px;
  background-color: #43bedd;
  border-radius: 30px 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 40px;
  color: white;
}

.contentInfo {
  width: 100%;
  border-radius: 5px;
}

.title {
  border-bottom: 1.5px solid #43bedd;
  margin-top: 20px;
  justify-content: space-between;
  margin-left: 20px;
  width: 90%;
  font-size: 20px;
  display: flex;
  color: #485462;
  margin-bottom: 20px;
  font-weight: 600;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.36px;
  color: #485462;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  margin-bottom: 4px;
  @media only screen and (max-width: 1400px) {
    max-width: 250px;
  }
  @media only screen and (min-width: 1900px) {
    max-width: 400px;
  }
}

.input {
  width: 100%;
  max-width: 275px;
  margin-left: 20px !important;
  background: #4854621a 0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #777777;
}

.textArea {
  width: 100%;
  max-width: 275px;
  margin-left: 20px !important;
  background: #4854621a 0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #777777;
  @media only screen and (max-width: 1400px) {
    max-width: 250px;
  }
  @media only screen and (min-width: 1900px) {
    max-width: 400px;
  }
}

.inputSingle {
  width: 100%;
  margin-left: 20px !important;
  background: #4854621a 0% 0% no-repeat padding-box;
  border-radius: 10px;
  mix-blend-mode: normal;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0.5rem;
  max-width: 300px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #777777;
  @media only screen and (max-width: 1366px) {
    max-width: 250px;
  }
  @media only screen and (min-width: 1900px) {
    max-width: 400px;
  }
}

.select {
  width: 100%;
  background: #4854621a 0% 0% no-repeat padding-box;
  border-radius: 10px;
  mix-blend-mode: normal;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0.5rem;
  max-width: 420px;
  font-style: normal;
  max-width: 150px;
  font-weight: 500;
  font-size: 14px;
  color: #777777;
}

.selectSingle {
  width: 100%;
  margin-left: 20px !important;
  background: #4854621a 0% 0% no-repeat padding-box;
  border-radius: 10px;
  mix-blend-mode: normal;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0.5rem;
  max-width: 420px;
  font-style: normal;

  font-weight: 500;
  font-size: 14px;
  color: #777777;
}
input[type="checkbox"] {
  accent-color: #5c82c2;
  width: 20px;
}

.check {
  margin-top: -10px;
  margin-left: 30px;
  width: 50px;
  height: 50px;
}

.contentButton {
  width: 100%;
  justify-content: center;
  display: flex;
}

.buttonSubmit {
  width: 100%;
  max-width: 600px;
  background: #485462 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #00000029;
  border: 2px solid #43bedd;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  font: normal normal normal 1.5rem Segoe UI;
  letter-spacing: 0px;
  color: #43bedd;
  cursor: pointer;
}

.buttonSubmit:hover {
  background: #43bedd;
  color: white;
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}

.contentError {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  font-size: 20px;
  color: white;
}

.iconError {
  color: red;
  font-size: 25px;
}

.contentInputs {
  width: 100%;
  max-width: 200px;
  justify-content: space-between;
  margin: auto;
}

.containerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  > div {
    > div:last-child {
      padding: 0 20px;
      > div {
        margin-left: 0;
        gap: 25px;
        > div {
          @media (max-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
          > label {
            margin: 0;
            width: 100%;
          }
          > input {
            margin: 0 !important;
            width: 100%;
          }
          > select {
            margin: 0 !important;
          }
        }
      }
    }
  }
}
