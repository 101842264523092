.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  padding: 8px;
  margin-bottom: 20px;
  z-index: 999;
  display: flex;
  align-items: center;
}

.image {
  width: 100%;
  max-width: 250px;
  margin-left: 50px;
  margin-top: 20px;
  object-fit: cover;
  @media (max-width: 768px) {
    max-width: 150px;
  }
}

.contentInfoProfile {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #485462;
  font-size: 12px;
  width: 100px;
  letter-spacing: 0.3px;
  font-weight: bold;
  cursor: pointer;
}

.icon {
  font-size: 12px;
  color: #5c82c2;
  margin-left: 3px;
  cursor: pointer;
}

.cargo {
  color: #485462;
  font-size: 10px;
  cursor: pointer;
}

.logOut {
  font-size: 10px;
  margin-top: 5px;
  color: #5c82c2;
  text-decoration: none;
  cursor: pointer;
}
