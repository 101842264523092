.container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: url('../../public/assets/background.png') no-repeat;
    background-size: 100%;
}

.inputLogin {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    max-width: 200px;
    border-radius: 5px;
    background-color: #f5f5f5;
    margin-bottom: 15px;
    margin-top: 5px;
}

label {
    text-align: start;
    margin-left: 30px;
    font-size: 12px;    
    color: #bcc0cc;
}

.buttonSubmit{
    padding: 10px;
    width: 100%;
    max-width: 220px;
    border-radius:5px;
    border: none;
    background-color: #43bedd;
    color: #fff;
    cursor: pointer;
}

.buttonSubmit:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}




.forgotPassword {
    margin-top: 10px;
    color: #8c8c8e;
    margin-bottom: 20px;
    font-size: 12px;
    cursor: pointer;
}

.accountValid {
    color: #48b1d7;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
}

.SignUp {
    color: #fff;
}


.error {
    width: 100%;
    color: red;
    font-size: 15px;
    text-align: center;
}