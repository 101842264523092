.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: url('../../public/assets/backgroundWelcome.jpg') no-repeat;
  background-size: 100%;
}
.welcomeContent {
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: auto;
}

.welcomeAdvert {
  width: 500px !important;
  max-width: 500px !important;
  margin-top: 50px;
}
  .start {
    margin-top: 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
    padding: 8px;
    background-color: #43bedd;
    color: white;
    font-size: 20px;
    cursor: pointer;
    
  }

  .start a {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }

.contentInfo {
  margin-top: 100px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.logoArPhoneix {
  margin-right: 20px;
}

.icon {
  font-size: 15px;
  color: white;
  margin: 10px;
}

.direct {
  font-size: 0.5rem;
  color: white;
}