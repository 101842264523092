body {
    background: #5C82C2;
}

.container { 
    padding-bottom: 4px 4px 160px 4px;
    padding: 4px !important;
    width: 80%;
    margin: auto;
    margin-top: 160px !important;
    margin-bottom: 40px !important;
}

.contentForm {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.content {
    width: 100%;
    background-color: white;
    flex-direction: row;
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #43BEDD;
    border-radius: 35px;
    margin-bottom: 20px;
}

.contentIcon {
    width: 60px;
    background-color: #43BEDD;
    border-radius: 30px 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    font-size: 40px;
    color: white;
}

.contentInfo {
    width: 100%;
}

.title  {
    border-bottom: 1.5px solid #43BEDD;
    margin-top: 20px;
    justify-content: space-between;
    margin-left: 20px;
    width: 90%;
    font-size: 20px;
    display: flex;
    color: #485462;
    margin-bottom: 20px;
}

.label {
    font-style: normal;
	font-weight: 600;
	font-size: 14px;
    text-align: left;
    letter-spacing: 0.36px;
    color: #485462;
    opacity: 1;
}

.input {
    width: 100%;
	margin-left: 20px !important;
	background: #4854621A 0% 0% no-repeat padding-box;
    border-radius: 10px;
	mix-blend-mode: normal;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 0.5rem;
    max-width: 280px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #777777;
}

.inputSingle {
    width: 100%;
	margin-left: 20px !important;
	background: #4854621A 0% 0% no-repeat padding-box;
    border-radius: 10px;
	mix-blend-mode: normal;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 0.5rem;
    max-width: 420px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #777777;
}

.select {
    width: 100%;
	margin-left: 20px !important;
	background: #4854621A 0% 0% no-repeat padding-box;
    border-radius: 10px;
	mix-blend-mode: normal;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 0.5rem;
    max-width: 420px;
	font-style: normal;
    max-width: 150px;
	font-weight: 500;
	font-size: 14px;
	color: #777777;
}

.selectSingle {
    width: 100%;
	margin-left: 20px !important;
	background: #4854621A 0% 0% no-repeat padding-box;
    border-radius: 10px;
	mix-blend-mode: normal;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 0.5rem;
    max-width: 420px;
	font-style: normal;

	font-weight: 500;
	font-size: 14px;
	color: #777777;
}
input[type=checkbox] {
    accent-color: #5C82C2;
    width: 20px;
}

.check {
    margin-top: -10px;
    margin-left: 30px;
    width: 50px;
    height: 50px;
}

.contentButton {
    width: 100%;
    justify-content: center;
    display: flex;
}

.buttonSubmit {
    width: 100%;
    max-width: 600px;
    background: #485462 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #00000029;
    border: 2px solid #43BEDD;
    border-radius: 15px;
    padding: 20px;
    color: white;
    text-align: center;
    font: normal normal normal 1.5rem Segoe UI;
    letter-spacing: 0px;
    color: #43BEDD;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
        filter: grayscale(1);
    }
}

.buttonSubmit:hover{
    background: #43BEDD;
    color: white;
}